/**
 * Labstep
 *
 * @module prosemirror/components/NodeView/File
 * @desc Renders a File node
 */

import FileImage from 'labstep-web/components/File/Image';
import React, { useEffect, useState } from 'react';
import { IWithResizeProps } from './types';

const ImageResizer: React.FC<IWithResizeProps> = ({
  file,
  resizeImage,
  attrs,
  focus,
  editable,
}) => {
  const [width, setWidth] = useState<number | null>(
    attrs.width || null,
  );
  const [isHovered, setIsHovered] = useState(false);

  const handleSliderChange = (event) => {
    setWidth(Number(event.target.value));
  };

  const [paragraphWidth, setParagraphWidth] = useState(100);
  useEffect(() => {
    setParagraphWidth(
      Math.floor(
        (
          (
            document.getElementById(
              `file-resize-${file.id}`,
            ) as HTMLElement
          ).parentElement?.closest('p') as HTMLElement
        ).getBoundingClientRect().width,
      ),
    );
  }, []);

  const widthStyle =
    width === null ? 'auto' : `${(width * paragraphWidth) / 100}px`;

  const containerStyle: any = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    width: '100%',
  };

  const imageContainerStyle = {
    width: widthStyle,
    height: 'auto',
    overflow: 'hidden',
  };

  const sliderContainerStyle: any = {
    opacity: isHovered ? 1 : 0,
    transition: 'all 0.3s ease-in-out',
    visibility: isHovered ? 'visible' : 'hidden',
    position: 'absolute',
    background: 'transparent',
    paddingTop: '30px',
    bottom: '20px',
    width: '100%',
    maxWidth: '600px',
    zIndex: 10,
  };

  const sliderStyle = {
    width: '100%',
    zIndex: 10,
    cursor: 'pointer',
  };

  const infoStyle = {
    fontFamily: 'sans-serif',
    color: '#666',
    fontSize: '0.9rem',
  };

  return (
    <div
      onDrag={(e) => e.preventDefault()}
      draggable={false}
      id={`file-resize-${file.id}`}
      style={containerStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={imageContainerStyle}>
        <FileImage file={file} expandable={false} />
      </div>
      {editable && (
        <div style={sliderContainerStyle} draggable={false}>
          <input
            id="image-resize-slider"
            draggable={false}
            type="range"
            min="20"
            max="100"
            value={width as number}
            onMouseDown={(e) => {
              e.stopPropagation();
              focus();
            }}
            onDragStart={(e) => {
              e.preventDefault();
            }}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSliderChange(e);
            }}
            style={sliderStyle}
            onMouseUp={() => {
              resizeImage({ width });
              setTimeout(() => {
                setIsHovered(true);
              }, 1000);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageResizer;
