/**
 * Labstep
 */

import { IDataGridProps } from 'labstep-web/core/DataGrid/types';
import colDefMetadataRowNumber from 'labstep-web/grid/Index/coldefs/Metadata/rowNumber';
import colDefResourceItem from 'labstep-web/grid/Index/coldefs/ProtocolValue';
import colDefAmount from 'labstep-web/grid/Index/coldefs/Resource/amount';
import { getHeaderParams } from 'labstep-web/grid/Index/coldefs/Resource/amount/utils';
import { getNestedEntity } from 'labstep-web/grid/SmartTable/coldefs/utils';
import { Experiment } from 'labstep-web/models/experiment.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { Protocol } from 'labstep-web/models/protocol.model';

export const getColumnDefs = (
  protocol: Experiment | Protocol,
  variableTemplate: ProtocolValue,
): NonNullable<IDataGridProps['columnDefs']> => [
  colDefMetadataRowNumber(protocol),
  colDefAmount({
    columnProps: {
      ...getHeaderParams(variableTemplate.is_input),
      flex: 1,
    },
    getNestedEntity: (params) =>
      getNestedEntity(params, 'protocol_values', variableTemplate),
  }),
  colDefResourceItem({
    columnProps: {
      flex: 1,
      suppressFillHandle: !variableTemplate.is_input,
    },
    getNestedEntity: (params) =>
      getNestedEntity(params, 'protocol_values', variableTemplate),
  }),
];
