/**
 * Labstep
 *
 * @module grid/IndexResourceItem
 * @desc Resource Item Index Grid
 * @url  /workspace/:workspaceId/resource-items
 */

import ResourceItemActionCreateTemplateMetadata from 'labstep-web/components/ResourceItem/Action/Create/Template/Metadata';
import { ICONS } from 'labstep-web/constants/icons';
import EntityDataGrid from 'labstep-web/grid/Index';
import MetadataDataGridColumnOptions from 'labstep-web/grid/Index/coldefs/Metadata/ColumnOptions';
import columnDefs from 'labstep-web/grid/IndexResourceItem/coldefs';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Metadata } from 'labstep-web/models/metadata';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import { objectOrFunction } from 'labstep-web/services/react.service';
import React from 'react';
import { GridResourceItemContainerProps } from './types';

export const GridResourceItemContainer: React.FC<
  GridResourceItemContainerProps
> = ({ templateId, resourceItems, ...rest }) => {
  if (templateId) {
    return (
      <ReadOnMountHOC
        type="entity"
        entityName={Resource.entityName}
        id={templateId}
        loading={{ loader: 'placeholder' }}
        children={({ entity: resourceTemplate }) => (
          <EntityDataGrid
            entities={resourceItems}
            columnDefs={objectOrFunction(
              columnDefs,
              resourceTemplate,
            )}
            toolPanelParams={{
              extraColumnOptions: resourceTemplate ? (
                <MetadataDataGridColumnOptions
                  entity={resourceTemplate}
                  extraFields={
                    <ResourceItemActionCreateTemplateMetadata
                      resource={resourceTemplate}
                      actionComponentProps={{
                        type: 'text',
                        icon: ICONS.metadata.secondary,
                        cornerIcon: ICONS.resource_item.primary,
                        text: `${ResourceItem.getHumanReadableEntityName(
                          false,
                          true,
                        )} ${Metadata.getHumanReadableEntityName(
                          false,
                          true,
                        )}`,
                      }}
                    />
                  }
                />
              ) : undefined,
            }}
            {...rest}
          />
        )}
      />
    );
  }
  return (
    <EntityDataGrid
      entities={resourceItems}
      columnDefs={objectOrFunction(columnDefs)}
      {...rest}
    />
  );
};

export default GridResourceItemContainer;
