/**
 * Labstep
 */

import EntityActionCreate from 'labstep-web/components/Entity/Action/Create';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { AmountUpdate } from 'labstep-web/models/amount-update.model';
import React from 'react';
import ProtocolValueActionUpdateAmountDeductedAtModal from './Modal';
import { getHeaderAndMessage } from './Modal/utils';
import { updateAmountDeductedAtActionComponentProps } from './constants';
import { IProtocolValueActionUpdateAmountDeductedAtProps } from './types';

export const ProtocolValueActionUpdateAmountDeductedAt: React.FC<
  IProtocolValueActionUpdateAmountDeductedAtProps
> = ({ protocolValue }) => {
  const canEdit = useHasAccess(
    protocolValue.entityName,
    protocolValue.guid,
    Action.edit,
    'amount_deducted_at',
  );
  if (protocolValue.is_output) {
    return null;
  }
  if (protocolValue.isAmountUpdated) {
    return null;
  }
  if (!(protocolValue.resourceItem && canEdit)) {
    return null;
  }
  if (getHeaderAndMessage(protocolValue).header) {
    return (
      <ProtocolValueActionUpdateAmountDeductedAtModal
        protocolValue={protocolValue}
      />
    );
  }

  return (
    <EntityActionCreate
      entityName={AmountUpdate.entityName}
      body={{
        protocol_value_id: protocolValue.guid,
      }}
      actionComponentProps={
        updateAmountDeductedAtActionComponentProps
      }
    />
  );
};

export default ProtocolValueActionUpdateAmountDeductedAt;
