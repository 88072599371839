/**
 * Labstep
 *
 * @module grid/SearchModal
 * @desc Used to search for Resource or ResourceItem using a grid view
 * @see /experiment-workflow/:experimentWorkflowId/inventory/:protocolValueId
 */

import EntityDataGrid from 'labstep-web/grid/Index';
import colDefBulkSelect, {
  colDefBulkSelectNoHeader,
} from 'labstep-web/grid/Index/coldefs/Entity/bulkSelect';
import React, { useCallback } from 'react';
import styles from './styles.module.scss';
import { GridSearchModalProps } from './types';

export const GridSearchModal: React.FC<GridSearchModalProps> = ({
  columnDefs,
  readNextPage,
  ...rest
}) => {
  const onCellKeyDown = useCallback((e) => {
    if (e.event instanceof KeyboardEvent && e.event.key === 'Enter') {
      e.node.setSelected(!e.node.isSelected());
    }
  }, []);
  return (
    <EntityDataGrid
      className={styles.dataGrid}
      loadMore={readNextPage}
      onCellKeyDown={onCellKeyDown}
      suppressRowClickSelection
      editable={false}
      columnDefs={[
        rest.rowSelection === 'multiple'
          ? colDefBulkSelect
          : colDefBulkSelectNoHeader,
        ...columnDefs,
      ]}
      onRowClicked={(e) => {
        e.node.setSelected(!e.node.isSelected());
      }}
      {...rest}
    />
  );
};

export default GridSearchModal;
