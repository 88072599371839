/**
 * Labstep
 *
 * @module grid/IndexResource
 * @desc Resource Index Grid
 * @url  /workspace/:workspaceId/resources
 */

import { useParamsContext } from 'labstep-web/contexts/params/hook';
import EntityDataGrid from 'labstep-web/grid/Index';
import MetadataDataGridColumnOptions from 'labstep-web/grid/Index/coldefs/Metadata/ColumnOptions';
import { generateResourceGridcoldefs } from 'labstep-web/grid/IndexResource/coldefs';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { GridResourceProps } from './types';

export const GridResource: React.FC<GridResourceProps> = ({
  resources,
  templateId,
}) => {
  const { setParams } = useParamsContext();

  if (templateId) {
    return (
      <ReadOnMountHOC
        type="entities"
        entityName={Resource.entityName}
        params={{ guid: templateId, get_single: 1 }}
        loading={{ loader: 'placeholder' }}
        children={({ entity: resourceTemplate }) => (
          <EntityDataGrid
            entities={resources}
            columnDefs={generateResourceGridcoldefs(
              setParams,
              resourceTemplate,
            )}
            toolPanelParams={{
              extraColumnOptions: (
                <MetadataDataGridColumnOptions
                  entity={resourceTemplate}
                />
              ),
            }}
          />
        )}
      />
    );
  }
  return (
    <EntityDataGrid
      entities={resources}
      columnDefs={generateResourceGridcoldefs(setParams)}
    />
  );
};

export default GridResource;
