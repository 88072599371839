/**
 * Labstep
 *
 * @module grid/IndexOrderRequest
 * @desc OrderRequest Index Grid
 * @url  /workspace/:workspaceId/order-requests
 */

import EntityDataGrid from 'labstep-web/grid/Index';
import React from 'react';
import { GridOrderRequestProps } from './types';

export const GridOrderRequest: React.FC<GridOrderRequestProps> = ({
  orderRequests,
  columnDefs,
}) => {
  return (
    <EntityDataGrid
      entities={orderRequests}
      columnDefs={columnDefs}
    />
  );
};

export default GridOrderRequest;
