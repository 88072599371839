/**
 * Labstep
 *
 * @module screens/EntityView/Show
 * @desc EntityView Show
 */

import { EntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { ProtocolConditionExplorerEmptyState } from 'labstep-web/components/ProtocolCondition/Explorer/EmptyState';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import { ParamsStateContextProvider } from 'labstep-web/contexts/params-state';
import GridReport from 'labstep-web/grid/Report';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import EntityViewShowBreadcrumb from 'labstep-web/screens/EntityView/Show/Breadcrumb';
import MasterIndex from 'labstep-web/screens/Master/Index';
import { ReportService } from 'labstep-web/services/report.service';
import React from 'react';
import {
  EntityViewShowContainerProps,
  IProtocolConditionShowProps,
} from './types';

export const ProtocolConditionShow: React.FC<
  IProtocolConditionShowProps
> = ({
  entityView,
  groupId,
  entityViewUpdate,
  entityViewUpdateStatus,
}) => {
  const breadcrumb = (
    <EntityViewShowBreadcrumb
      entityView={entityView}
      groupId={groupId}
      entityViewUpdateStatus={entityViewUpdateStatus}
    />
  );
  const noResultsMessage = <ProtocolConditionExplorerEmptyState />;

  const globalParams: Record<
    string,
    string | number | Record<string, unknown>[]
  > = {
    group_id: groupId,
    serializerGroups: 'protocol_condition_reporting,paginator',
    sort: '-created_at',
    count: 50,
  };

  const paramsStateContext = {
    persistEntity: ProtocolCondition.entityName,
    globalParams,
    initialSearchParams:
      ReportService.injectFilterOutExperimentWorkflowTemplateParam(
        entityView.parameters,
      ) || {},
    entityView,
    entityViewUpdate,
    entityViewUpdateStatus,
  };

  return (
    <ParamsStateContextProvider value={paramsStateContext}>
      <MasterIndex
        breadcrumb={breadcrumb}
        entityName={ProtocolCondition.entityName}
        noResultsMessage={noResultsMessage}
        entityView={{
          entityView,
          context: 'reporting',
          entityName: ProtocolCondition.entityName,
        }}
        tableFormat={false}
        hideFilters
        reportingFilters={[Filter.entity_state_name]}
        usePostFilter
        useAllPages
      >
        {({
          entities,
          readNextPage,
          status,
        }: EntitySearchContainerChildrenProps) => (
          <GridReport
            entityView={entityView}
            protocolConditions={entities}
            loadMore={readNextPage}
            status={status}
          />
        )}
      </MasterIndex>
    </ParamsStateContextProvider>
  );
};

const EntityViewShowContainer: React.FC<
  EntityViewShowContainerProps
> = ({ match }) => (
  <ReadOnMountHOC
    type="entities"
    entityName={EntityView.entityName}
    params={{
      get_single: 1,
      group_id: match.params.id,
      id: match.params.entityViewId,
      context: 'reporting',
      serializerGroups: 'entity_view_reporting',
    }}
    loading={{ loader: 'show', cached: true }}
    children={({ entity }: { entity: EntityView }) => {
      return (
        <EntityUpdateContainer
          entityName={EntityView.entityName}
          id={entity.guid}
        >
          {({ update, status }) => (
            <ProtocolConditionShow
              groupId={match.params.id}
              entityView={entity}
              entityViewUpdate={update}
              entityViewUpdateStatus={status}
            />
          )}
        </EntityUpdateContainer>
      );
    }}
  />
);

export default EntityViewShowContainer;
