import ProtocolConditionActionCompareDataProtocolCollection from 'labstep-web/components/ProtocolCondition/Action/CompareData/ProtocolCollection';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import React from 'react';
import ProtocolConditionActionCompareDataMetadata from './Metadata';
import ProtocolConditionActionCompareDataProtocolValue from './ProtocolValue';
import { ProtocolConditionActionCompareDataProps } from './types';

export const ProtocolConditionActionCompareData: React.FC<
  ProtocolConditionActionCompareDataProps
> = ({
  activeGroup,
  protocolCollection,
  metadata,
  protocolValue,
  actionComponentType,
}) => {
  if (!useHasFeatureFlagEnabled('reporting')) {
    return null;
  }
  if (!activeGroup) {
    return null;
  }

  if (protocolCollection) {
    return (
      <ProtocolConditionActionCompareDataProtocolCollection
        group={activeGroup}
        protocolCollection={protocolCollection}
        actionComponentType={actionComponentType}
      />
    );
  }

  if (metadata) {
    return (
      <ProtocolConditionActionCompareDataMetadata
        group={activeGroup}
        metadata={metadata}
        actionComponentType={actionComponentType}
      />
    );
  }

  if (protocolValue) {
    return (
      <ProtocolConditionActionCompareDataProtocolValue
        group={activeGroup}
        protocolValue={protocolValue}
        actionComponentType={actionComponentType}
      />
    );
  }

  return null;
};

export default withActiveGroup(ProtocolConditionActionCompareData);
