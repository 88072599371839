/**
 * Labstep
 *
 * @module screens/Inventory
 * @desc Inventory Screen (resources + items)
 */

import LayoutLinksMobile from 'labstep-web/components/Layout/Links/Mobile';
import { EntityCardLink } from 'labstep-web/components/Layout/Links/types';
import { ICONS } from 'labstep-web/constants/icons';
import Icon from 'labstep-web/core/Icon';
import ScreensResourceIndex from 'labstep-web/screens/Resource/Index';
import ScreensResourceItemIndex from 'labstep-web/screens/ResourceItem/Index';
import React from 'react';
import { IScreensInventoryProps } from './types';

const ScreensInventory: React.FC<IScreensInventoryProps> = ({
  group,
}) => {
  const links: EntityCardLink[] = [
    {
      children: (
        <span>
          <Icon name={ICONS.resource.primary} />
          {`Resource View`}
        </span>
      ),
      route: {
        to: 'group_resources',
        params: { id: group.id },
      },
    },
    {
      children: (
        <span>
          <Icon name={ICONS.resource_item.primary} />
          {`Resource Item View`}
        </span>
      ),
      route: {
        to: 'group_resource_items',
        params: { id: group.id },
      },
    },
  ];

  const screens = [
    (args: any) => (
      <ScreensResourceIndex {...args} group={group} links={links} />
    ),
    (args: any) => (
      <ScreensResourceItemIndex
        {...args}
        group={group}
        links={links}
      />
    ),
  ];

  return <LayoutLinksMobile links={links} screens={screens} />;
};

export default ScreensInventory;
