/**
 * Labstep
 *
 * @module components/EntityView/Action/Save
 * @desc Action to save EntityView
 */

import { useAgGridReportServiceFromContext } from 'labstep-web/contexts/grid/hook';
import { useParamsStateContext } from 'labstep-web/contexts/params-state/hook';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { navigation } from 'labstep-web/services/navigation';
import { isEqual } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router';
import { EntityViewActionCreateProps } from './types';

export const EntityViewActionSave: React.FC<
  EntityViewActionCreateProps
> = ({ history }) => {
  const { searchParams } = useParamsContext();

  const paramsStateContext = useParamsStateContext();

  const gridReportService = useAgGridReportServiceFromContext();

  if (!paramsStateContext) {
    return null;
  }

  const {
    entityView,
    entityViewUpdate,
    entityViewCreate,
    entityViewUpdateStatus,
  } = paramsStateContext;

  if (!gridReportService || !entityView) {
    return null;
  }

  const newState = gridReportService.getState();
  const newColumnDefinitionIds =
    gridReportService.getColumnDefinitionIds();

  if (!entityView.id) {
    return (
      <ActionComponent
        type="button"
        icon="save"
        showIcon
        elementProps={{ basic: true }}
        text="Save as Report"
        onClick={() => {
          if (gridReportService && entityViewCreate) {
            entityViewCreate(
              {
                entity_name: ProtocolCondition.entityName,
                name: entityView.name,
                group_id: entityView.group.guid,
                parameters: searchParams,
                state: newState,
                column_definition_ids: newColumnDefinitionIds,
              },
              {
                onSuccess: ({
                  response,
                }: {
                  response: {
                    result: string;
                  };
                }) => {
                  history.push(
                    navigation.get('entity_view_show', {
                      id: entityView.group.id,
                      entityViewId: response.result,
                    }),
                  );
                },
              },
            );
          }
        }}
      />
    );
  }

  if (
    isEqual(entityView.parameters, searchParams) &&
    isEqual(entityView.state, newState) &&
    isEqual(entityView.column_definition_ids, newColumnDefinitionIds)
  ) {
    return null;
  }

  return (
    <ActionComponent
      type="button"
      text="Save View"
      icon="save"
      showIcon
      status={entityViewUpdateStatus}
      elementProps={{ basic: true }}
      onClick={() => {
        if (gridReportService && entityViewUpdate) {
          entityViewUpdate(
            {
              parameters: searchParams,
              state: newState,
              column_definition_ids: newColumnDefinitionIds,
            },
            {},
          );
        }
      }}
    />
  );
};

export default withRouter(EntityViewActionSave);
