/**
 * Labstep
 *
 * @desc A service to deal with amount and units
 */

import { UNITS, Unit } from 'labstep-web/constants/unit';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import * as math from 'mathjs';

const SCALE = 5;

const FORMAT_OPTIONS = {
  notation: 'fixed',
} as math.FormatOptions;

const SCALING_OPTIONS = {
  ...FORMAT_OPTIONS,
  precision: SCALE,
} as math.FormatOptions;

export interface IAmountUnit {
  amount: string;
  unit: string | null;
}

/**
 * Get unit modifier if exists
 */
export const getUnitModifier = (unit: string | null) =>
  unit && UNITS[unit.toLowerCase()]
    ? UNITS[unit.toLowerCase()].modifier
    : 0;

/**
 * Compute amount relative to base
 *
 * @param string amount
 * @param string unit
 *
 * @return number
 */
export const amountRelBase = (
  amount: string,
  unit: string | null,
): number => {
  const modifier = getUnitModifier(unit);
  return math.evaluate(`${amount}*(10^${modifier})`);
};

export const checkUnitBase = (
  protocolValueUnit: string,
  resourceItemUnit: string,
) => {
  const protocolValueBase = getBase(protocolValueUnit);
  const resourceItemBase = getBase(resourceItemUnit);

  return protocolValueBase === resourceItemBase;
};

/**
 * Round to scale (mimics brick math)
 */
export const scaleAmount = (amount: any) => {
  if (!amount) {
    return amount;
  }

  return math.bignumber(math.format(amount, SCALING_OPTIONS));
};

/**
 * Format amount to string
 * @param amount Amount to be converted
 * @param withScale If true, use scale for rounding
 * @returns Formatted amount string
 */
export const formatAmount = (amount: any, withScale?: boolean) => {
  if (!amount) {
    return null;
  }

  let stringValue = math.format(
    amount,
    withScale ? SCALING_OPTIONS : FORMAT_OPTIONS,
  );
  if (stringValue.includes('.')) {
    stringValue = stringValue.replace(/0+$/, '');
    if (stringValue.slice(-1) === '.') {
      stringValue = stringValue.slice(0, -1);
    }
  }
  return stringValue;
};

/**
 * Modify amount to adjust to units.
 */
export const modifyAmount = (
  amount: string | number | math.BigNumber,
  from: string,
  to: string,
): string | null => {
  const power = getUnitModifier(from) - getUnitModifier(to);
  const scope = {
    amount,
    power,
  };
  return formatAmount(math.evaluate(`amount*(10^power)`, scope));
};

/** Combine amount and unit into string */
export const getAmountUnitText = (
  amount: string | null | undefined,
  unit: string | null | undefined,
  noUnitOnly?: boolean,
): string | null => {
  if (!(amount || unit)) {
    return null;
  }
  if (amount && unit) {
    return `${amount} ${unit}`;
  }
  if (amount) {
    return amount;
  }
  if (unit && !noUnitOnly) {
    return unit;
  }
  return null;
};

/** Convert amount with unit to amount
 * If not valid amount, return false
 */
export const convertAmountUnitToAmount = (
  amountUnit: string | number,
): string | false => {
  const result = String(amountUnit)
    .replace(/[^0-9.]/g, '')
    .trim();
  return result.length ? result : false;
};

export const convertAmountUnitToUnit = (
  amountUnit: string | number,
): string | false => {
  const result = String(amountUnit)
    .replace(/[0-9.,]/g, '')
    .trim();
  return result.length ? result : false;
};

const getAmounts = (
  protocolValue: ProtocolValue,
): {
  amountTobeUpdated: number | null;
  resourceItemAmount: number | null;
} => {
  if (
    protocolValue.resourceItem &&
    protocolValue.resourceItem.amount !== null &&
    protocolValue.resourceItem.unit &&
    protocolValue.amount !== null &&
    protocolValue.unit
  ) {
    const protocolValueAmount = amountRelBase(
      protocolValue.amount,
      protocolValue.unit,
    );
    const resourceItemAmount = amountRelBase(
      protocolValue.resourceItem.amount,
      protocolValue.resourceItem.unit,
    );
    const amountTobeUpdated = math.subtract(
      protocolValueAmount,
      parseInt(protocolValue.amount_deducted, 10),
    );

    return {
      amountTobeUpdated,
      resourceItemAmount,
    };
  }
  return {
    amountTobeUpdated: null,
    resourceItemAmount: null,
  };
};

export const canUpdateAmount = (
  protocolValue: ProtocolValue,
): boolean => {
  const { amountTobeUpdated, resourceItemAmount } =
    getAmounts(protocolValue);

  if (amountTobeUpdated && resourceItemAmount !== null) {
    return (
      Number(math.compare(amountTobeUpdated, resourceItemAmount)) <= 0
    );
  }
  return false;
};

/**
 * Validate and process value if numeric
 * Returns false if invalid
 * @param value Value
 * @returns Numeric value or false
 */
export const validateNumeric = (
  value: unknown,
): string | false | null => {
  if (value === null) {
    return value as null;
  }
  // '10 g' should become '10'
  const amount = convertAmountUnitToAmount(String(value));
  if (amount === false) {
    return false;
  }
  return amount;
};

/**
 * Validate and process value if unit
 * Returns false if invalid
 * @param value Value
 * @returns Unit or false
 */
export const validateUnit = (
  value: unknown,
): string | false | null => {
  if (value === null) {
    return value as null;
  }
  // '10 g' should become 'g'
  const unit = convertAmountUnitToUnit(String(value));
  if (unit === false) {
    return false;
  }
  return unit;
};

export const getBase = (unit: string) => {
  if (!unit) {
    return '';
  }
  const normalizedUnit = unit.trim().toLowerCase();

  if (UNITS[normalizedUnit]) {
    return UNITS[normalizedUnit].base;
  }

  return normalizedUnit;
};

export const UNITS_CHEMICAL = Object.values(UNITS).filter(
  (u) => u.base === 'l' || u.base === 'g',
);

export const unitsAsOptions = (
  isChemical = false,
  additionalUnit?: string,
) => {
  const unitsToUse = isChemical
    ? UNITS_CHEMICAL
    : Object.values(UNITS);
  const units = unitsToUse
    .filter((unit: Unit) => unit.displayValue)
    .map((unit: Unit) => ({
      label: unit.displayValue,
      value: unit.name,
    }));

  if (additionalUnit && !(additionalUnit in UNITS)) {
    return [
      { label: additionalUnit, value: additionalUnit },
      ...units,
    ];
  }
  return units;
};
