/**
 * Labstep
 *
 * @module core/Metadata/Variable/Detail
 * @desc Metadata Variable Detail
 */

import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import GridMetadataVariable from 'labstep-web/grid/SmartTableMetadataVariable';
import React from 'react';
import { MetadataVariableDetailProps } from './types';

export const MetadataVariableDetail: React.FC<
  MetadataVariableDetailProps
> = ({ protocol, metadata }) => {
  const { isOpen } = useProtocolConditionModalContext();
  if (isOpen) {
    return null;
  }
  return (
    <GridMetadataVariable
      protocol={protocol}
      variableTemplate={metadata}
    />
  );
};

export default MetadataVariableDetail;
