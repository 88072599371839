/**
 * Labstep
 *
 * @module models/protocol-value
 * @desc Typescript export class for ProtocolValue
 */

import { Type } from 'class-transformer';
import { UNITS } from 'labstep-web/constants/unit';
import { Entity } from 'labstep-web/models/entity.model';
import { Experiment } from 'labstep-web/models/experiment.model';
import { Log } from 'labstep-web/models/log.model';
import { ProtocolStep } from 'labstep-web/models/protocol-step.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import {
  amountRelBase,
  getAmountUnitText,
} from 'labstep-web/services/amount-unit.service';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export type ProtocolValueEntityNameType = 'protocol_value';

export const INPUT_OUTPUT_TYPE_INPUT = 'input';
export const INPUT_OUTPUT_TYPE_OUTPUT = 'output';

export const INPUT_LABEL = 'Input';
export const OUTPUT_LABEL = 'Output';

export interface Option {
  label: typeof INPUT_LABEL | typeof OUTPUT_LABEL;
  value:
    | typeof INPUT_OUTPUT_TYPE_INPUT
    | typeof INPUT_OUTPUT_TYPE_OUTPUT;
}

export const INPUT_OUTPUT_TYPES: Option[] = [
  {
    label: INPUT_LABEL,
    value: INPUT_OUTPUT_TYPE_INPUT,
  },
  {
    label: OUTPUT_LABEL,
    value: INPUT_OUTPUT_TYPE_OUTPUT,
  },
];

export const getLabel = (
  name: string,
  amount: string | null,
  unit: string | null,
): string => {
  const amountUnitText = getAmountUnitText(amount, unit);

  if (!amountUnitText) {
    return name;
  }

  return `${name} (${amountUnitText})`;
};

export const getProtocolValueLabel = (
  protocolValue: ProtocolValue,
): string => {
  const { resource, name, amount, unit } = protocolValue;
  const finalName =
    name ||
    resource?.name || // eslint-disable-next-line @typescript-eslint/no-use-before-define
    `Untitled ${ProtocolValue.getHumanReadableEntityName(
      false,
      true,
    )}`;
  return getLabel(finalName, amount, unit);
};

export class ProtocolValue extends Entity {
  public static readonly idAttr = 'guid' as const;

  public static readonly entityName: ProtocolValueEntityNameType =
    'protocol_value';

  public get entityName(): string {
    return ProtocolValue.entityName;
  }

  public constructor(data: Partial<ProtocolValue> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public name!: string | null;

  public amount!: string | null;

  public amount_deducted!: string;

  public amount_deducted_unit!: string | null;

  private _unit!: string | null;

  public _is_input!: boolean;

  public _is_output!: boolean;

  public is_lineage!: boolean;

  public amount_deducted_at!: string | null;

  @Type(() => Log)
  public amount_deducted_log!: Log | null;

  public is_experiment_child!: boolean;

  public has_chemical!: boolean;

  public variable_template_guid!: string | null;

  public is_variable!: boolean;

  public has_non_limiting_reactant!: boolean;

  public protocol_condition_guid!: string | null;

  public get label(): string {
    return getProtocolValueLabel(this);
  }

  public get displayName(): string {
    return this.label;
  }

  @Type(() => Experiment)
  public experiment: Experiment | null | undefined;

  @Type(() => ProtocolStep)
  public protocol_step: ProtocolStep | null | undefined;

  @Type(() => Protocol)
  public protocol: Protocol | null | undefined;

  @Type(() => Resource)
  public _resource: Resource | null | undefined;

  @Type(() => Resource)
  public _resource_template: Resource | null | undefined;

  @Type(() => ResourceItem)
  public resource_item: ResourceItem | null | undefined;

  @Type(() => ResourceItem)
  public resource_item_output: ResourceItem | null | undefined;

  @Type(() => ProtocolValue)
  public variable_template: ProtocolValue | null | undefined;

  public get is_input(): boolean {
    if (this.variable_template) {
      return this.variable_template.is_input;
    }
    return this._is_input;
  }

  public set is_input(is_input: boolean) {
    this._is_input = is_input;
  }

  public get is_output(): boolean {
    if (this.variable_template) {
      return this.variable_template.is_output;
    }
    return this._is_output;
  }

  public set is_output(is_output: boolean) {
    this._is_output = is_output;
  }

  public get unit(): string | null {
    if (this.variable_template) {
      return this.variable_template.unit;
    }
    return this._unit;
  }

  public set unit(unit: string | null) {
    this._unit = unit;
  }

  public get resource(): Resource | null | undefined {
    if (this.variable_template) {
      return this.variable_template.resource;
    }
    return this._resource;
  }

  public set resource(resource: Resource | null | undefined) {
    this._resource = resource;
  }

  public get resource_template(): Resource | null | undefined {
    if (this.variable_template) {
      return this.variable_template.resource_template;
    }
    return this._resource_template;
  }

  public set resource_template(resource_template:
    | Resource
    | null
    | undefined) {
    this._resource_template = resource_template;
  }

  public get resourceItem(): ResourceItem | null | undefined {
    return this.is_output
      ? this.resource_item_output
      : this.resource_item;
  }

  public get canCreateItem(): boolean {
    return this.is_output && !this.resource_item_output;
  }

  public get inputOutputTypeOption(): Option {
    if (this.is_output) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return INPUT_OUTPUT_TYPES.find(
        (option: Option) => option.value === INPUT_OUTPUT_TYPE_OUTPUT,
      )!;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return INPUT_OUTPUT_TYPES.find(
      (option: Option) => option.value === INPUT_OUTPUT_TYPE_INPUT,
    )!;
  }

  public get inputOutputTypeLabel(): string {
    return this.inputOutputTypeOption.label;
  }

  public get parent(): Experiment | Protocol {
    return (this.experiment || this.protocol)!;
  }

  public get isAmountUpdated(): boolean {
    if (
      this.amount !== null &&
      this.unit &&
      this.amount_deducted_unit
    ) {
      const deductedAmount = amountRelBase(
        this.amount_deducted,
        this.amount_deducted_unit,
      );
      const protocolValueAmount = amountRelBase(
        this.amount,
        this.unit,
      );
      return protocolValueAmount === deductedAmount;
    }
    return false;
  }

  public get unitDisplayValue(): string | null | undefined {
    const { unit } = this;
    if (!unit) {
      return null;
    }

    if (UNITS[unit]) {
      return UNITS[unit].displayValue;
    }
    return unit;
  }

  public get amountUnitText(): string | null {
    return getAmountUnitText(this.amount, this.unitDisplayValue);
  }

  public get amountUnitTextNoUnitOnly(): string | null {
    return getAmountUnitText(
      this.amount,
      this.unitDisplayValue,
      true,
    );
  }

  public get reportingName(): string {
    return this.name || this.resourceItem?.name || '';
  }

  public getColId(field?: string): string {
    return `protocol_value:${this.is_input ? 'input' : 'output'}${
      this.reportingName
    }${field ? `:${field}` : ''}`;
  }

  public static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }

  public static getIsInputFromColId(colId: string): boolean {
    const colIdParts = colId.split(':');
    return colIdParts.length > 2 ? Boolean(colIdParts[1]) : false;
  }

  public static getNameFromColId(colId: string): string {
    const colIdParts = colId.split(':');
    return colIdParts.length > 2 ? colIdParts[2] : '';
  }

  public static getFieldFromColId(colId: string): string {
    const colIdParts = colId.split(':');
    return colIdParts.length > 3 ? colIdParts[3] : '';
  }
}
