/**
 * Labstep
 *
 * @module core/TextLink
 * @desc Text with Metadata
 */

import EntityLink from 'labstep-web/components/Entity/Link';
import EntityPreview from 'labstep-web/components/Entity/Preview';
import React from 'react';
import styles from './styles.module.scss';
import { ITextLinkProps } from './types';

export const TextLink: React.FC<ITextLinkProps> = ({
  entity,
  disabled,
  entityPreviewProps,
}) => (
  <div className={styles.text}>
    <EntityLink entity={entity as any} disabled={disabled} />
    {entityPreviewProps && (
      <div className={styles.preview}>
        <EntityPreview
          entity={entity}
          type="icon"
          {...entityPreviewProps}
        />
      </div>
    )}
  </div>
);

export default TextLink;
