/**
 * Labstep
 *
 * @module components/OrderRequest/ListOrTable
 * @desc List or table of orderRequests
 */

import OrderRequestList from 'labstep-web/components/OrderRequest/List';
import GridIndexOrderRequest from 'labstep-web/grid/IndexOrderRequest';
import MasterIndexCenterListOrTable from 'labstep-web/screens/Master/Index/Center/ListOrTable';
import React from 'react';
import { actions } from './constants';
import { OrderRequestListOrTableProps } from './types';

export const OrderRequestListOrTable: React.FC<
  OrderRequestListOrTableProps
> = ({ orderRequests, columnDefs }) => (
  <MasterIndexCenterListOrTable
    tableComponent={
      <GridIndexOrderRequest
        orderRequests={orderRequests}
        columnDefs={columnDefs}
      />
    }
    listComponent={
      <OrderRequestList
        orderRequests={orderRequests}
        actions={actions}
      />
    }
  />
);

export default OrderRequestListOrTable;
