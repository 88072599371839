/**
 * Labstep
 *
 * @module components/EntityView/Action/Delete
 * @desc Action to delete EntityView
 */

import DeleteAction from 'labstep-web/components/Entity/Action/Delete';
import React from 'react';
import { EntityViewDeleteActionProps } from './types';

export const EntityViewDeleteAction: React.FC<
  EntityViewDeleteActionProps
> = ({ entityView }) => <DeleteAction entity={entityView} />;

export default EntityViewDeleteAction;
