/**
 * Labstep
 *
 * @module screens/EntityView/Show/Breadcrumb
 * @desc EntityView Show Breadcrumb
 */

import EntityPrimaryInfo from 'labstep-web/components/Entity/PrimaryInfo';
import EntityViewActionMenu from 'labstep-web/components/EntityView/Action/Menu';
import { Action } from 'labstep-web/components/EntityView/Action/Menu/types';
import { ICONS } from 'labstep-web/constants/icons';
import Breadcrumb from 'labstep-web/core/Breadcrumb';
import Flex from 'labstep-web/core/Flex';
import Loader from 'labstep-web/core/Loader';
import React from 'react';
import { EntityViewShowBreadcrumbProps } from './types';

export const EntityViewShowBreadcrumb: React.FC<
  EntityViewShowBreadcrumbProps
> = ({ entityView }) => (
  <Flex grow>
    <EntityPrimaryInfo
      entity={entityView}
      icon={ICONS.entity_view.primary}
      name={entityView.name}
      withPermaLink={false}
    />
    <EntityViewActionMenu
      entityView={entityView}
      actions={[Action.delete, Action.add_to_folder, Action.tag]}
    />
  </Flex>
);

export default EntityViewShowBreadcrumb;
