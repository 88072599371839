/**
 * Labstep
 *
 * @module report/services/grid-report
 * @desc AGGrid Service Reporting
 */

import { arrayMove } from '@dnd-kit/sortable';
import { Column, HeaderClassParams } from 'ag-grid-community';
import { ISortableProps } from 'labstep-web/core/Sortable/types';
import {
  COL_ID_MAPPING,
  experimentNameColDef,
  getMetadataExperimentColDef,
  getMetadataResourceColDef,
  getMetadataResourceItemColDef,
  getProtocolValueFieldColumnDef,
  getProtocolValueResourceItemNameColumnDef,
  getProtocolValueResourceLocationPathColumnDef,
  getProtocolValueResourceNameColumnDef,
} from 'labstep-web/grid/Report/coldefs';
import { ReportingColDef } from 'labstep-web/grid/Report/coldefs/types';
import { GridService } from 'labstep-web/grid/services/grid.service';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { forEach, get } from 'lodash';

export interface IEntityDataGridToolPanelSectionItemProps {
  column: ColumnWithId;
  section: SectionType;
  onToggleColumnVisible: (colId?: string) => void;
}

// eslint-disable-next-line no-shadow
export enum SectionType {
  visible = 'visible',
  hidden = 'hidden',
}
export interface IEntityDataGridToolPanelSectionProps {
  columns: Column[];
  section: SectionType;
  onToggleColumnVisible: IEntityDataGridToolPanelSectionItemProps['onToggleColumnVisible'];
  onSortColumns: (columns: Column[]) => void;
}

export type ColumnWithId = Column & {
  getId: () => string;
};

export type ItemWithColumn = {
  id: string;
  column: ColumnWithId;
};

export const PROTOCOL_VALUE_FIELDS = [
  'amount',
  'resource_item_name',
  'resource_name',
  'resource_location_path',
];

export class GridReportService extends GridService {
  /**
   * Returns a function bound to the onSortEnd prop of dnd-kit sortable
   * @param items ItemWithColumn array
   * @param fn Function to call when sorting is complete
   * @returns Column array
   */
  public static onSortEndColumns(
    items: ItemWithColumn[],
    fn: (columns: Column[]) => void,
  ): ISortableProps<ItemWithColumn>['onSortEnd'] {
    return ({ oldIndex, newIndex }) => {
      const newItems = arrayMove(items, oldIndex, newIndex);
      const newColumns = GridReportService.itemsToColumns(newItems);
      fn(newColumns);
    };
  }

  /**
   * Creates an array of objects with the column and its id
   * For use with dnd-kit sortable
   * @param columns Column array
   * @returns ItemWithColumn array
   */
  public static columnsToItems(columns: Column[]): ItemWithColumn[] {
    return columns
      .filter((column) => column.getId()) // sanity type check
      .map((column) => ({
        id: column.getId(),
        column,
      }));
  }

  /**
   * Creates an array of columns from an array of objects with the column and its id
   * @param items ItemWithColumn array
   * @returns Column array
   */
  public static itemsToColumns(items: ItemWithColumn[]): Column[] {
    return items.map((item) => item.column);
  }

  public static isHideable(column: HeaderClassParams['column']) {
    if (!column) {
      return false;
    }
    return (
      !column.isPinned() &&
      !column.getColDef().lockPinned &&
      !column.getColDef().lockVisible
    );
  }

  public static showListSection = (
    labels: string[],
    searchQuery: string | null | undefined,
  ) =>
    !searchQuery ||
    searchQuery.length === 0 ||
    labels
      .map((label) => label.includes(searchQuery.toLowerCase()))
      .includes(true);

  public static showListItem = (
    label: string,
    searchQuery: string | null | undefined,
  ) =>
    !searchQuery ||
    searchQuery.length === 0 ||
    label.includes(searchQuery.toLowerCase());

  public static metadataCellRenderer = (
    metadatas: Metadata[],
    label: string,
  ) => {
    const foundMetadata = metadatas
      ? metadatas.find(
          (dataMetadata: Metadata) => dataMetadata.label === label,
        )
      : null;
    if (!foundMetadata) {
      return '';
    }
    return foundMetadata.display_value || '';
  };

  public static protocolValueCellRenderer = (
    protocolValues: ProtocolValue[],
    name: string,
    path: string,
  ) => {
    const foundProtocolValue = GridReportService.getProtocolValue(
      protocolValues,
      name,
    );
    if (!foundProtocolValue) {
      return '';
    }
    return get(foundProtocolValue, path) || '';
  };

  public static getProtocolValue = (
    protocolValues: ProtocolValue[],
    name: string,
  ) =>
    protocolValues
      ? protocolValues.find(
          (dataProtocolValue: ProtocolValue) =>
            dataProtocolValue.name === name,
        )
      : null;

  public static getColDefs = (
    entityView: EntityView,
  ): ReportingColDef[] => {
    if (!entityView.column_definition_ids) {
      return [experimentNameColDef];
    }

    const colDefs: ReportingColDef[] = [];
    forEach(entityView.column_definition_ids, (colId: string) => {
      if (COL_ID_MAPPING[colId]) {
        colDefs.push(COL_ID_MAPPING[colId]);
      }
      if (colId.startsWith('metadata:experiment:')) {
        const type = Metadata.getTypeFromColId(colId);
        const label = Metadata.getLabelFromColId(colId);
        colDefs.push(getMetadataExperimentColDef(type, label));
      }
      if (colId.startsWith('metadata:resource:')) {
        const type = Metadata.getTypeFromColId(colId);
        const label = Metadata.getLabelFromColId(colId);
        const protocolValueName =
          Metadata.getProtocolValueNameFromColId(colId);
        colDefs.push(
          getMetadataResourceColDef(protocolValueName, type, label),
        );
      }
      if (colId.startsWith('metadata:resource_item:')) {
        const type = Metadata.getTypeFromColId(colId);
        const label = Metadata.getLabelFromColId(colId);
        const protocolValueName =
          Metadata.getProtocolValueNameFromColId(colId);
        colDefs.push(
          getMetadataResourceItemColDef(
            protocolValueName,
            type,
            label,
          ),
        );
      }
      if (
        colId.startsWith('protocol_value:') &&
        colId.endsWith(':amount')
      ) {
        const isInput = ProtocolValue.getIsInputFromColId(colId);
        const name = ProtocolValue.getNameFromColId(colId);
        colDefs.push(
          getProtocolValueFieldColumnDef(isInput, name, 'amount'),
        );
      }
      if (
        colId.startsWith('protocol_value:') &&
        colId.endsWith(':resource_name')
      ) {
        const isInput = ProtocolValue.getIsInputFromColId(colId);
        const name = ProtocolValue.getNameFromColId(colId);
        colDefs.push(
          getProtocolValueResourceNameColumnDef(isInput, name),
        );
      }
      if (
        colId.startsWith('protocol_value:') &&
        colId.endsWith(':resource_item_name')
      ) {
        const isInput = ProtocolValue.getIsInputFromColId(colId);
        const name = ProtocolValue.getNameFromColId(colId);
        colDefs.push(
          getProtocolValueResourceItemNameColumnDef(isInput, name),
        );
      }
      if (
        colId.startsWith('protocol_value:') &&
        colId.endsWith(':resource_location_path')
      ) {
        const isInput = ProtocolValue.getIsInputFromColId(colId);
        const name = ProtocolValue.getNameFromColId(colId);
        colDefs.push(
          getProtocolValueResourceLocationPathColumnDef(
            isInput,
            name,
          ),
        );
      }
    });
    return colDefs;
  };

  public static getMetadataColDefs = (
    metadata: Metadata,
  ): ReportingColDef[] => {
    const columnDefs: ReportingColDef[] = [experimentNameColDef];

    if (metadata.label && metadata.label !== '') {
      columnDefs.push(
        getMetadataExperimentColDef(metadata.type, metadata.label),
      );
    }

    return columnDefs;
  };

  public static getProtocolColDefs = (
    protocol: Protocol,
  ): ReportingColDef[] => {
    const columnDefs: Record<string, ReportingColDef> = {
      [experimentNameColDef.colId as string]: experimentNameColDef,
    };

    protocol.metadatas_without_variable_template.forEach(
      (metadata) => {
        if (metadata.label && metadata.label !== '') {
          const metadataExperimentColDef =
            getMetadataExperimentColDef(
              metadata.type,
              metadata.label,
            );
          columnDefs[metadataExperimentColDef.colId as string] =
            metadataExperimentColDef;
        }
      },
    );

    protocol.protocol_values_without_variable_template.forEach(
      (protocolValue) => {
        if (protocolValue.name) {
          const protocolValueFieldColumnDef =
            getProtocolValueFieldColumnDef(
              protocolValue.is_input,
              protocolValue.reportingName,
              'amount',
            );
          columnDefs[protocolValueFieldColumnDef.colId as string] =
            protocolValueFieldColumnDef;

          const protocolValueResourceNameColumnDef =
            getProtocolValueResourceNameColumnDef(
              protocolValue.is_input,
              protocolValue.reportingName,
            );
          columnDefs[
            protocolValueResourceNameColumnDef.colId as string
          ] = protocolValueResourceNameColumnDef;

          const protocolValueResourceItemNameColumnDef =
            getProtocolValueResourceItemNameColumnDef(
              protocolValue.is_input,
              protocolValue.reportingName,
            );
          columnDefs[
            protocolValueResourceItemNameColumnDef.colId as string
          ] = protocolValueResourceItemNameColumnDef;

          const protocolValueResourceLocationPathColumnDef =
            getProtocolValueResourceLocationPathColumnDef(
              protocolValue.is_input,
              protocolValue.reportingName,
            );
          columnDefs[
            protocolValueResourceLocationPathColumnDef.colId as string
          ] = protocolValueResourceLocationPathColumnDef;
        }

        protocolValue.resource_template?.resource_item_template?.metadata_thread.metadatas.forEach(
          (metadata) => {
            if (metadata.label && metadata.label !== '') {
              const metadataResourceItemColDef =
                getMetadataResourceItemColDef(
                  protocolValue.reportingName,
                  metadata.type,
                  metadata.label,
                );
              columnDefs[metadataResourceItemColDef.colId as string] =
                metadataResourceItemColDef;
            }
          },
        );

        if (protocolValue.resource_template) {
          protocolValue.resource_template?.metadata_thread.metadatas.forEach(
            (metadata) => {
              if (metadata.label && metadata.label !== '') {
                const metadataResourceColDef =
                  getMetadataResourceColDef(
                    protocolValue.reportingName,
                    metadata.type,
                    metadata.label,
                  );
                columnDefs[metadataResourceColDef.colId as string] =
                  metadataResourceColDef;
              }
            },
          );
        }
      },
    );

    return Object.values(columnDefs);
  };

  public addColumnDef(colDef: ReportingColDef): void {
    const currentColDefs = this.getColumnDefs();

    if (currentColDefs.find((col) => col.colId === colDef.colId)) {
      this.setColumnHide(colDef.colId, false);

      return;
    }

    this.setColumnDefs([...this.getColumnDefs(), colDef]);
  }

  public getColumnDefinitionIds = (): string[] => {
    const columnDefs = this.getColumnDefs();
    const state = this.getState();

    return columnDefs
      .filter((colDef) => {
        const columnState = state.find(
          (colState) => colState.colId === colDef.colId,
        );
        if (!columnState) {
          return false;
        }
        return !columnState.hide;
      })
      .map((colDef) => colDef.colId || '');
  };
}
