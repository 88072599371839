/**
 * Labstep
 */

import { selectHasAccess } from 'labstep-web/components/Entity/Can/hooks';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { getProps } from 'labstep-web/components/ProtocolValue/Form/ShowEdit/ResourceItem/Input/utils';
import { getOnChange } from 'labstep-web/components/ProtocolValue/Form/ShowEdit/ResourceItem/Output';
import ResourceItemActionCreateProtocolValue from 'labstep-web/components/ResourceItem/Action/CreateProtocolValue';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import DataGrid from 'labstep-web/core/DataGrid';
import { ICellEditorSearchSelectModalProps } from 'labstep-web/core/DataGrid/CellEditor/SearchSelect/Modal/types';
import DataGridPlaceholder from 'labstep-web/core/DataGrid/Placeholder';
import TextLink from 'labstep-web/core/DataGrid/Text/Link';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import {
  getPropOrFallback,
  updateValue,
} from 'labstep-web/core/DataGrid/utils';
import TextPlaceholder from 'labstep-web/core/Text/Placeholder';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import ResourceItemShowCenterContentSecondaryInfo from 'labstep-web/screens/ResourceItem/Show/Center/SecondaryInfo';
import { isValid } from 'labstep-web/services/validation';
import rules from 'labstep-web/services/validation/rules';
import store from 'labstep-web/state/store';
import React from 'react';
import { colDefResourceItemType } from './types';

const RULE = rules.protocol_value.amount;
export const COL_ID_RESOURCE_ITEM = 'resourceItem';

/**
 * Column definition for Protocol Value amount
 * @param getPropNested Custom fn to get Protocol Value as nested field
 * @param colProps Customisation of grid column
 * @returns Column definition
 */
const colDefResourceItem: colDefResourceItemType = (options = {}) => {
  const { getNestedEntity, columnProps } = options;
  const getProp = getPropOrFallback(getNestedEntity) as any;
  const cellEditorParams = (
    params: any,
  ): Partial<ICellEditorSearchSelectModalProps<ProtocolValue>> =>
    getProp(params, (entity: any) => {
      const props = getProps(entity);
      if (entity.is_output) {
        props.onChange = (option): void =>
          getOnChange(entity)(option);
        props.preventDefaultOnChange = true;
        props.createBody = {
          ...props.createBody,
          amount: entity.amount,
          unit: entity.unit,
        };
      }
      return {
        props,
      };
    });

  const cellRenderer = (params: any) =>
    getProp(params, (entity: any) => {
      const canEditInput = selectHasAccess(
        store.getState(),
        entity.entityName,
        entity.idAttr,
        Action.edit,
        ['resource_item'],
      );
      const canEditOutput = selectHasAccess(
        store.getState(),
        entity.entityName,
        entity.idAttr,
        Action.edit,
        ['resource_item_output'],
      );
      if (
        (entity.is_input && !canEditInput) ||
        (entity.is_output && !canEditOutput)
      ) {
        return <TextPlaceholder>No Value</TextPlaceholder>;
      }
      if (entity.resourceItem) {
        return (
          <EntityReadEntityContainer
            entityName={ResourceItem.entityName}
            id={entity.resourceItem.id}
          >
            {({ entity: resourceItem }) => (
              <TextLink
                entity={resourceItem}
                entityPreviewProps={{
                  secondaryInfo: (
                    <ResourceItemShowCenterContentSecondaryInfo
                      resourceItem={resourceItem}
                    />
                  ),
                }}
                disabled
              />
            )}
          </EntityReadEntityContainer>
        );
      }
      return entity.is_input ? (
        <DataGridPlaceholder
          children={
            entity.is_experiment_child ? 'Select' : 'To be selected'
          }
          params={params}
          disabled={!entity.is_experiment_child}
          // show placeholder so user understands field in protocol
          editable
        />
      ) : (
        <EntityReadEntityContainer
          entityName={entity.entityName}
          id={entity.guid}
        >
          {({ entity: protocolValue }): React.ReactElement => (
            <ResourceItemActionCreateProtocolValue
              protocolValue={protocolValue}
              actionComponentProps={{
                disabled: !protocolValue.is_experiment_child,
                type: 'text',
                text: protocolValue.is_experiment_child
                  ? 'Create'
                  : 'To be created',
                elementProps: {
                  placeholder: true,
                  gridCell: true,
                },
              }}
            />
          )}
        </EntityReadEntityContainer>
      );
    });

  const valueGetter = (params: any): number | false | null =>
    getProp(
      params,
      (entity: any) => entity.resourceItem?.id || null,
      false,
    );

  const valueSetter: ColDef<ProtocolValue>['valueSetter'] = (
    params,
  ): boolean =>
    !!getProp(params, (entity) => {
      const value = params.newValue;

      if (isValid(value, RULE)) {
        if (entity.is_input) {
          updateValue(entity, 'resource_item_id', value);
        } else if (value) {
          updateValue(
            { entityName: ResourceItem.entityName, id: value },
            'protocol_value_origin_guid',
            entity.guid,
          );
        } else if (entity.resource_item_output) {
          updateValue(
            {
              entityName: ResourceItem.entityName,
              id: entity.resource_item_output.id,
            },
            'protocol_value_origin_guid',
            null,
          );
        } else {
          return false;
        }
        return true;
      }

      return false;
    });

  // sadly AG Grid has not typed the fn type of this param
  const suppressFillHandle: any = (params: any): boolean => {
    // resource item outputs should be unique for each condition
    const isOutput = getProp(
      params,
      (entity: any) => entity.is_output,
    );
    return isOutput === null ? true : isOutput;
  };

  const suppressPaste = (params: any): boolean => {
    // resource item outputs should be unique for each condition
    const isOutput = getProp(
      params,
      (entity: any) => entity.is_output,
    );
    return isOutput === null ? true : isOutput;
  };

  return {
    colId: COL_ID_RESOURCE_ITEM,
    cellEditor: DataGrid.CellEditor.SearchSelectModal,
    cellEditorParams,
    cellEditorPopup: true,
    cellRenderer,
    editable: (params): boolean =>
      !!getProp(
        params,
        (entity: any) =>
          entity.is_experiment_child &&
          selectHasAccess(
            store.getState(),
            entity.entityName,
            entity.idAttr,
            Action.edit,
            entity.is_input
              ? ['resource_item']
              : ['resource_item_output'],
          ),
      ),
    headerName: ResourceItem.getHumanReadableEntityName(false, true),
    headerComponentParams: { icon: ICONS.resource_item.primary },
    suppressPaste,
    suppressFillHandle,
    valueGetter,
    valueSetter,
    ...columnProps,
  };
};

export default colDefResourceItem;
