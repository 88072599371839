import FilterEntity from 'labstep-web/components/Filter/Entity';
import Container from 'labstep-web/components/Layout/Container';
import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import { ICONS } from 'labstep-web/constants/icons';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityReadEntityContainer } from 'labstep-web/containers/Entity/Read/Entity';
import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Default';
import SearchSelect from 'labstep-web/core/Select/Search';
import { Device } from 'labstep-web/models/device.model';
import React from 'react';
import {
  DeviceChildrenProps,
  ExperimentWorkflowFilterDeviceSelectProps,
} from './types';

export const ExperimentWorkflowFilterDeviceSelect: React.FC<
  ExperimentWorkflowFilterDeviceSelectProps
> = ({ setParams, searchParams }) => {
  let children = null;

  if ('device_id' in searchParams) {
    children = (
      <EntityReadEntityContainer
        entityName={Device.entityName}
        id={(searchParams.device_id as number) || 'random'}
      >
        {({ entity: device }: DeviceChildrenProps) => (
          <>
            <SearchSelect
              entityName={Device.entityName}
              params={{}}
              onChange={(selectedOption) => {
                setParams({
                  device_id: selectedOption
                    ? selectedOption.guid
                    : undefined,
                });
              }}
              value={device}
            />
          </>
        )}
      </EntityReadEntityContainer>
    );
  } else {
    children = (
      <EntityReadEntityContainer
        entityName={Device.entityName}
        id={(searchParams.device_id as number) || 'random'}
      >
        {({ entity: device }: DeviceChildrenProps) => (
          <>
            <SearchSelect
              entityName={Device.entityName}
              params={{}}
              onChange={(selectedOption) => {
                setParams({
                  device_id: selectedOption
                    ? selectedOption.id
                    : undefined,
                });
              }}
              value={device || null}
            />
          </>
        )}
      </EntityReadEntityContainer>
    );
  }
  return (
    <Modal
      header="Filter by device"
      content={({ toggleModal }) => (
        <div>
          <Container>{children}</Container>
          <LayoutContainerActionButtons>
            <ActionComponent
              type="button"
              text="Done"
              onClick={toggleModal}
            />
          </LayoutContainerActionButtons>
        </div>
      )}
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="option"
          icon={ICONS.device.primary}
          text="Device used"
          onClick={toggleModal}
        />
      )}
    />
  );
};

class ExperimentWorkflowFilterDeviceSelectContainer extends React.Component<
  ExperimentWorkflowFilterDeviceSelectProps,
  { shouldRenderWithFilter: boolean }
> {
  constructor(props: ExperimentWorkflowFilterDeviceSelectProps) {
    super(props);
    this.state = {
      shouldRenderWithFilter: 'device_id' in props.searchParams,
    };
  }

  render() {
    const { searchParams, setParams, ...rest } = this.props;
    const children = (
      <ExperimentWorkflowFilterDeviceSelect
        setParams={setParams}
        searchParams={searchParams}
        {...rest}
      />
    );
    if (!this.state.shouldRenderWithFilter) {
      return children;
    }
    let entityName;
    let searchParamKey;

    if (searchParams.device_id) {
      entityName = Device.entityName;
      searchParamKey = 'device_id';
    } else {
      return children;
    }

    return (
      <FilterEntity
        entityName={entityName}
        searchParamKey={searchParamKey}
        searchParams={searchParams}
      >
        {() => children}
      </FilterEntity>
    );
  }
}

export default withActiveGroup(
  ExperimentWorkflowFilterDeviceSelectContainer,
);
